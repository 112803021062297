import React from 'react'
import { graphql } from 'gatsby'
import NotFoundPage from '../pages/404'

export const MenuSettingsTemplate = ({ id }) => {
  return <NotFoundPage />
}

export default ({ data }) => {
  const { markdownRemark: navSettings } = data

  return <MenuSettingsTemplate id={navSettings.id} />
}

export const MenuSettingsQuery = graphql`
  query MenuSettings($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        menuItems {
          label
          path
        }
      }
    }
  }
`
